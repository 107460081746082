var listAu = {
    listGender: [
        { text: '', value: ''},
        { text: 'Male', value: 'Male'},
        { text: 'Female', value: 'FeMale'},
        { text: 'Indeterminate', value: 'Indeterminate'}
    ],
    listState: [
        { text: 'ACT', value: 'ACT'},
        { text: 'NSW', value: 'NSW'},
        { text: 'NT', value: 'NT'},
        { text: 'QLD', value: 'QLD'},
        { text: 'SA', value: 'SA'},
        { text: 'TAS', value: 'TAS'},
        { text: 'VIC', value: 'VIC'},
        { text: 'WA', value: 'WA'},
        { text: 'Other', value: 'OTHER'}
    ],
    listEmploymentType: [
        { text: 'Casual', value: 'Casual'},
        { text: 'Full Time', value: 'FullTime'},
        { text: 'Part Time', value: 'PartTime'}
    ],
    listPayPeriod: ["All", "Weekly", "Fortnightly", "Monthly"],
    listTypeRate: [
        { text: 'Per Hour', value: 'PerHour'},
        { text: 'Per Day', value: 'PerDay'},
        { text: 'Per Week', value: 'PerWeek'},
        { text: 'Per Annum', value: 'PerAnnum'}
    ],
    listPaymentMethod: ["Bank", "Cash"],
    listStatusResidency: [
        { text: 'Australia Resident', value: 'AustraliaResident'},
        { text: 'Foreign Resident', value: 'ForeignResident'},
        { text: 'Working Holiday', value: 'WorkingHoliday'}
    ],
    listTfnExemption: [
        { text: 'No TFN Quoted', value: 'NoTFNQuoted'},
        { text: 'TFN Pending', value: 'TFNPending'},
        // { text: 'Under 18', value: 'Under18'},
        { text: 'Pensioner', value: 'Pensioner'}
    ],
    listOptionExtraHours: [
        { text:"Ordinary hours", value:"ORDINARY_HOURS"},
        { text:"Overtime - exempt from super", value:"OVERTIME_EXEMPT_FROM_SUPER"}
    ],
    // listOptionExtraHours_new: [
    //     {
    //         id: 1,
    //         payItemType: "Normal Hours",
    //         payslipName: 'Normal Earnings',
    //         normalRate: '1.0',
    //         calculateTax: true,
    //         calculateSuper: true,
    //         leave: true,
    //         fixedRate: 0,
    //         overwriteFixedRate: false,
    //         atoReportingCategory: ''
    //     },
    //     {
    //         id: 2,
    //         payItemType: "Overtime",
    //         payslipName: 'Overtime x1.5',
    //         normalRate: '1.5',
    //         calculateTax: true,
    //         calculateSuper: false,
    //         leave: false,
    //         fixedRate: 0,
    //         overwriteFixedRate: false,
    //         atoReportingCategory: ''
    //     },
    //     {
    //         id: 3,
    //         payItemType: "Other",
    //         payslipName: 'Extra',
    //         normalRate: 'fixed',
    //         calculateTax: true,
    //         calculateSuper: false,
    //         leave: false,
    //         fixedRate: 75.0,
    //         overwriteFixedRate: true,
    //         atoReportingCategory: ''
    //     }
    // ],
    listOptionAllowance: [
        { value: "SUBJECT_TO_TAX_AND_SUPER", text: "Subject to tax and super"},
        { value: "SUBJECT_TO_TAX_EXEMPT_FROM_SUPER", text: "Subject to tax, Exempt from super"},
        { value: "EXEMPT_FROM_TAX_AND_SUPER", text: "Exempt from tax and super"}
    ],
    // listTypeAllowance: [
    //     { value: "CAR", text: "Car"},
    //     { value: "TRANSPORT", text: "Transport"},
    //     { value: "LAUNDRY", text: "Laundry"},
    //     { value: "MEALS", text: "Meals"},
    //     { value: "TRAVEL", text: "Travel"},
    //     { value: "OTHER", text: "Other"}
    // ],
    listOptionDeduction: [
        { value: "POST_TAX_DEDUCTION", text: "Post tax deduction"},
        { value: "PRE_TAX_DEDUCTION", text: "Pre tax deduction"}
    ],
    listTypeDeduction: [
        { value: "FEES", text: "Fees"},
        { value: "WORKPLACE_GIVING", text: "Workplace Giving"}
    ],
    listTypeSuper: [
        "Regulated Superannuation Fund",
        "Self Managed Superannuation Fund"
    ],
    listRole: [
        { text: 'Employer', value: 'User'},
        { text: 'Accountant', value: 'Accountant'}
    ],
    LeaveStatus: [
        { text: 'Pending', value: 'Pending'},
        { text: 'Approved', value: 'Approved'},
        { text: 'Reject', value: 'Reject'},
        { text: 'Completing', value: 'Completing'},
        { text: 'Completed', value: 'Completed'},
    ]
}

var listVN = {
    listGender: [
        { text: '', value: ''},
        { text: 'Male', value: 'Male'},
        { text: 'Female', value: 'FeMale'},
        { text: 'Indeterminate', value: 'Indeterminate'}
    ],
    listState: [
        { text: 'ACT', value: 'ACT'},
        { text: 'NSW', value: 'NSW'},
        { text: 'NT', value: 'NT'},
        { text: 'QLD', value: 'QLD'},
        { text: 'SA', value: 'SA'},
        { text: 'TAS', value: 'TAS'},
        { text: 'VIC', value: 'VIC'},
        { text: 'WA', value: 'WA'},
        { text: 'Other', value: 'OTHER'}
    ],
    listEmploymentType: [
        { text: 'Casual', value: 'Casual'},
        { text: 'Full Time', value: 'FullTime'},
        { text: 'Part Time', value: 'PartTime'}
    ],
    listPayPeriod: ["All", "Weekly", "Fortnightly", "Monthly"],
    listTypeRate: [
        { text: 'Per Hour', value: 'PerHour'},
        { text: 'Per Day', value: 'PerDay'},
        { text: 'Per Week', value: 'PerWeek'},
        { text: 'Per Annum', value: 'PerAnnum'}
    ],
    listPaymentMethod: ["Bank", "Cash"],
    listStatusResidency: [
        { text: 'Australia Resident', value: 'AustraliaResident'},
        { text: 'Foreign Resident', value: 'ForeignResident'},
        { text: 'Working Holiday', value: 'WorkingHoliday'}
    ],
    listTfnExemption: [
        { text: 'No TFN Quoted', value: 'NoTFNQuoted'},
        { text: 'TFN Pending', value: 'TFNPending'},
        // { text: 'Under 18', value: 'Under18'},
        { text: 'Pensioner', value: 'Pensioner'}
    ],
    listOptionExtraHours: [
        { text:"Ordinary hours", value:"ORDINARY_HOURS"},
        { text:"Overtime - exempt from super", value:"OVERTIME_EXEMPT_FROM_SUPER"}
    ],
    listOptionExtraHoursEarnings: [
        { value: "SUBJECT_TO_TAX_AND_SUPER", text: "Subject to tax and super"},
        { value: "SUBJECT_TO_TAX_EXEMPT_FROM_SUPER", text: "Subject to tax, Exempt from super"},
        { value: "EXEMPT_FROM_TAX_AND_SUPER", text: "Exempt from tax and super"}
    ],
    listOptionAllowance: [
        { value: "SUBJECT_TO_TAX_AND_SUPER", text: "Subject to tax and super"},
        { value: "SUBJECT_TO_TAX_EXEMPT_FROM_SUPER", text: "Subject to tax, Exempt from super"},
        { value: "EXEMPT_FROM_TAX_AND_SUPER", text: "Exempt from tax and super"}
    ],
    listTypeAllowance: [
        { value: "CAR", text: "Car"},
        { value: "TRANSPORT", text: "Transport"},
        { value: "LAUNDRY", text: "Laundry"},
        { value: "MEALS", text: "Meals"},
        { value: "TRAVEL", text: "Travel"},
        { value: "OTHER", text: "Other"}
    ],
    listOptionDeduction: [
        { value: "POST_TAX_DEDUCTION", text: "Post tax deduction"},
        { value: "PRE_TAX_DEDUCTION", text: "Pre tax deduction"}
    ],
    listTypeDeduction: [
        { value: "UNION_FEES", text: "Union Fees"},
        { value: "WORKPLACE_GIVING", text: "Workplace Giving"},
        { value: "SUPER_SALARY_SACRIFICE", text: "Super Salary Sacrifice"},
        { value: "SUPER_PERSONAL_CONTRIBUTIONS", text: "Super Personal Contributions"},
        { value: "ETP", text: "Etp"},
        { value: "OTHER", text: "Other"}
    ],
    listTypeSuper: [
        "Regulated Superannuation Fund",
        "Self Managed Superannuation Fund"
    ],
    listRole: [
        { text: 'Employer', value: 'User'},
        { text: 'Accountant', value: 'Accountant'}
    ],
    LeaveStatus: [
        { text: 'Pending', value: 'Pending'},
        { text: 'Approved', value: 'Approved'},
        { text: 'Reject', value: 'Reject'},
        { text: 'Completing', value: 'Completing'},
        { text: 'Completed', value: 'Completed'},
    ]
}


var settingsPayItems = {
    allowancesPayItem: [
        {"atoReportingCategory": "","calculateSuper": false,"calculateTax": true ,"id": 0
         ,"leave": false,"normalRate": "fixed","otherName": "","payItemType": "Allowances","payslipName": "CAR"},
         {"atoReportingCategory": "","calculateSuper": false,"calculateTax": true ,"id": 0
         ,"leave": false,"normalRate": "fixed","otherName": "","payItemType": "Allowances","payslipName": "LAUNDRY"},
         {"atoReportingCategory": "","calculateSuper": false,"calculateTax": true ,"id": 0
         ,"leave": false,"normalRate": "fixed","otherName": "","payItemType": "Allowances","payslipName": "TRANSPORT"},
         {"atoReportingCategory": "","calculateSuper": false,"calculateTax": true ,"id": 0
         ,"leave": false,"normalRate": "fixed","otherName": "","payItemType": "Allowances","payslipName": "MEALS"},
         {"atoReportingCategory": "","calculateSuper": false,"calculateTax": true ,"id": 0
         ,"leave": false,"normalRate": "fixed","otherName": "","payItemType": "Allowances","payslipName": "TRAVEL"},
         {"atoReportingCategory": "","calculateSuper": false,"calculateTax": true ,"id": 0
         ,"leave": false,"normalRate": "fixed","otherName": "Other","payItemType": "Allowances","payslipName": "OTHER"},
    ],
    earningsPayItem: [
        {"atoReportingCategory": "","calculateSuper": true,"calculateTax": true ,"id": 0
        ,"leave": true,"normalRate": "1.0","otherName": "","payItemType": "Earnings","payslipName": "Normal Earnings"},
        {"atoReportingCategory": "","calculateSuper": false,"calculateTax": true ,"id": 0
         ,"leave": false,"normalRate": "1.5","otherName": "","payItemType": "Earnings","payslipName": "Overtime x1.5"},
         {"atoReportingCategory": "","calculateSuper": false,"calculateTax": true ,"id": 0
         ,"leave": false,"normalRate": "2.0","otherName": "","payItemType": "Earnings","payslipName": "Overtime x2.0"},
    ],
    deductionsEmpPayItem: [
        {"atoReportingCategory": "","calculateSuper": false,"calculateTax": true ,"id": 0
         ,"leave": false,"normalRate": "fixed","otherName": "","payItemType": "Deductions","payslipName": "UNION_FEES"},
         {"atoReportingCategory": "","calculateSuper": false,"calculateTax": true ,"id": 0
         ,"leave": false,"normalRate": "fixed","otherName": "","payItemType": "Deductions","payslipName": "WORKPLACE_GIVING"},
         {"atoReportingCategory": "","calculateSuper": false,"calculateTax": true ,"id": 0
         ,"leave": false,"normalRate": "fixed","otherName": "","payItemType": "Deductions","payslipName": "SUPER_SALARY_SACRIFICE"},
         {"atoReportingCategory": "","calculateSuper": false,"calculateTax": true ,"id": 0
         ,"leave": false,"normalRate": "2.0","otherName": "","payItemType": "Deductions","payslipName": "SUPER_PERSONAL_CONTRIBUTIONS"},
         {"atoReportingCategory": "","calculateSuper": false,"calculateTax": true ,"id": 0
         ,"leave": false,"normalRate": "2.0","otherName": "","payItemType": "Deductions","payslipName": "ETP"},
         {"atoReportingCategory": "","calculateSuper": false,"calculateTax": true ,"id": 0
         ,"leave": false,"normalRate": "2.0","otherName": "Other","payItemType": "Deductions","payslipName": "OTHER"},
    ]
}

var zone = "AU"

function getList(name) {
    switch(zone){
        case "AU":
            return listAu[name];
        case "VN":
            return listVN[name];
    }
}

function setZone(code) {
    zone = code;
}

export {
    getList,
    setZone,
    settingsPayItems
}